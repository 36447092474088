import React from 'react';
import Link from 'gatsby-link';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { MEDIA_QUERY, COLOR } from '../styles/variables';
import { MAIL_ADDRESS, TWITTER_ACCOUNT, COMMON_TEXT } from '../utils/word';
import { Logo } from './Logo';
import { containerStyle } from '../styles/common';

const Footer = (): JSX.Element => {
  return (
    <FOOTER_Component>
      <DIV_Container>
        <DIV_Site>
          <Link to="/">
            <Logo color={COLOR.footer.logo} width={280} />
          </Link>
          <DIV_SiteExplain>{COMMON_TEXT.ABOUT}</DIV_SiteExplain>

          <DIV_Bio>
            <P_Mail>
              <a href={`mailto:${MAIL_ADDRESS}`}>
                <FontAwesomeIcon icon={faEnvelope} />
                <SPAN_LinkText>{MAIL_ADDRESS}</SPAN_LinkText>
              </a>
            </P_Mail>

            <P_Twitter>
              <a href="https://twitter.com/fe__designer">
                <FontAwesomeIcon icon={faTwitter} />
                <SPAN_LinkText>{TWITTER_ACCOUNT}</SPAN_LinkText>
              </a>
            </P_Twitter>
          </DIV_Bio>

          <P_Policy>
            <Link to="/about/">
              このサイトについて（プライバシーポリシー、免責事項）
            </Link>
          </P_Policy>

          <P_Freepik>
            <A_FreepikLink href="https://stories.freepik.com/web">
              Illustration by Freepik Stories
            </A_FreepikLink>
          </P_Freepik>
        </DIV_Site>
      </DIV_Container>
    </FOOTER_Component>
  );
};

const FOOTER_Component = styled.footer`
  position: relative;
  padding: 60px 20px;
  color: ${COLOR.footer.text};
  background-size: cover;
  background-image: linear-gradient(
    90deg,
    ${COLOR.gradation.start} 0%,
    ${COLOR.gradation.middle} 49%,
    ${COLOR.gradation.end} 100%
  );

  @media (max-width: ${MEDIA_QUERY.middle}) {
    padding: 120px 20px;
  }
`;

const DIV_Container = styled.div`
  ${containerStyle}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10;
`;

const DIV_Site = styled.div`
  text-align: left;

  @media (max-width: ${MEDIA_QUERY.middle}) {
    text-align: left;
  }
`;

const DIV_SiteExplain = styled.div`
  margin: 8px auto 0;
  font-size: 1.6rem;
  line-height: 1.8;

  @media (min-width: ${MEDIA_QUERY.middle}) {
    margin: 12px auto 0;
    font-size: 1.8rem;
  }
`;

const DIV_Bio = styled.div`
  @media (min-width: ${MEDIA_QUERY.middle}) {
    display: flex;
    justify-content: flex-start;
  }
`;

const SPAN_LinkText = styled.span`
  margin-left: 8px;
`;

const P_Mail = styled.p`
  margin-top: 8px;
`;

const P_Twitter = styled.p`
  position: relative;
  margin-top: 8px;

  @media (min-width: ${MEDIA_QUERY.middle}) {
    margin-left: 20px;
    padding-left: 20px;

    &::before {
      content: '';
      display: block;
      width: 1px;
      height: 10px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: ${COLOR.footer.text};
    }
  }
`;

const P_Policy = styled.p`
  margin: 8px auto;
`;

const P_Freepik = styled.p`
  margin: 8px auto;
`;

const A_FreepikLink = styled.a``;

export { Footer };

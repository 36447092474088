import React from 'react';
import styled from '@emotion/styled';
import { useStaticQuery, graphql, Link } from 'gatsby';

import { COLOR, LAYER, TRANSITION } from '../styles/variables';
import { CategoryList } from './CategoryList';

type Props = {
  isMenuOpen: boolean;
};

const Menu = (props: Props): JSX.Element => {
  const { isMenuOpen } = props;

  const data = useStaticQuery(graphql`
    query {
      allContentfulCategory {
        nodes {
          category
          categorySlug
        }
      }
    }
  `);

  return (
    <DIV_Component isMenuOpen={isMenuOpen}>
      <UL_List>
        <LI_Item>
          <Link to="/">TOP</Link>
        </LI_Item>
        <LI_Item>
          <Link to="/article/">ARTICLE</Link>
        </LI_Item>
        <LI_Item>
          <P_Title>CATEGORY</P_Title>
          <DIV_Categories>
            <CategoryList data={data.allContentfulCategory.nodes} />
          </DIV_Categories>
        </LI_Item>
        <LI_Item>
          <Link to="/about/">ABOUT</Link>
        </LI_Item>
      </UL_List>
    </DIV_Component>
  );
};

const DIV_Component = styled.header<{ isMenuOpen: boolean }>`
  display: block;
  position: fixed;
  top: 0;
  right: -280px;
  bottom: 0;
  width: 280px;
  background: ${COLOR.menu.contentBg};
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition: ${TRANSITION.menu};
  opacity: 0;
  z-index: ${LAYER.menuContent};

  ${({ isMenuOpen }): string =>
    isMenuOpen
      ? `
      right: 0;
      opacity: 1;
    `
      : ``}
`;

const UL_List = styled.ul`
  padding: 60px 20px 40px;
  text-align: center;
`;

const LI_Item = styled.li`
  border-bottom: solid 1px ${COLOR.menu.contentBorder};

  &:first-of-type {
    border-top: solid 1px ${COLOR.menu.contentBorder};
  }

  > a {
    display: block;
    padding: 20px;
    font-weight: bold;

    &:hover {
      background-color: ${COLOR.menu.contentHover};
    }
  }
`;

const P_Title = styled.p`
  display: block;
  padding: 20px;
  text-align: center;
  font-weight: bold;
`;

const DIV_Categories = styled.div`
  padding-bottom: 20px;
`;

export { Menu };

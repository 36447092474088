import React, { useState } from 'react';
import Link from 'gatsby-link';
import styled from '@emotion/styled';
import {
  MEDIA_QUERY,
  COLOR,
  LAYER,
  TRANSITION,
  SIZE,
} from '../styles/variables';
import { Logo } from './Logo';
import { Menu } from './Menu';
import { containerStyle } from '../styles/common';

const Header = (): JSX.Element => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    event.preventDefault();
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <HEADER_component>
      <DIV_Container>
        <DIV_SiteLogo>
          <Link to="/">
            <Logo color={COLOR.key} width={280} />
          </Link>
        </DIV_SiteLogo>
        <BUTTON_MenuButton
          onClick={toggleMenu}
          isMenuOpen={isMenuOpen}
          aria-label={isMenuOpen ? 'メニューを閉じる' : 'メニューを開く'}
        >
          <SPAN_MenuLine isMenuOpen={isMenuOpen} />
          <SPAN_MenuLine isMenuOpen={isMenuOpen} />
          <SPAN_MenuLine isMenuOpen={isMenuOpen} />
        </BUTTON_MenuButton>
        <Menu isMenuOpen={isMenuOpen} />
      </DIV_Container>
      <BUTTON_MenuBg
        onClick={toggleMenu}
        isMenuOpen={isMenuOpen}
        aria-label={isMenuOpen ? 'メニューを閉じる' : 'メニューを開く'}
      />
    </HEADER_component>
  );
};

const HEADER_component = styled.header``;

const DIV_SiteLogo = styled.div`
  width: 200px;

  @media (min-width: ${MEDIA_QUERY.middle}) {
    width: 240px;
  }

  a {
    display: block;
  }
`;

const DIV_Container = styled.div`
  ${containerStyle}
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
`;

const BUTTON_MenuButton = styled.button<{ isMenuOpen: boolean }>`
  position: absolute;
  z-index: ${LAYER.menuButton};
  display: block;
  width: 40px;
  height: 40px;
  right: 8px;

  @media (min-width: ${MEDIA_QUERY.max}) {
    right: calc((100vw - ${SIZE.container.max}) / 2);
  }

  &:focus {
    outline: none;
  }

  ${({ isMenuOpen }): string | boolean =>
    isMenuOpen &&
    `
      position: fixed;
      right: 300px !important;
    `};
`;

const SPAN_MenuLine = styled.span<{ isMenuOpen: boolean }>`
  display: block;
  position: absolute;
  left: 5px;
  width: 30px;
  height: 2px;
  background-color: ${COLOR.key};
  transition: ${TRANSITION.menu};
  border-radius: 4px;

  ${({ isMenuOpen }): string | boolean =>
    isMenuOpen &&
    `
      background-color: #fff;
    `};

  &:nth-of-type(1) {
    top: 10px;

    ${({ isMenuOpen }): string | boolean =>
      isMenuOpen &&
      `
        transform: translateY(15px) rotate(-45deg);
        top: 0;
    `};
  }
  &:nth-of-type(2) {
    top: 20px;

    ${({ isMenuOpen }): string | boolean =>
      isMenuOpen &&
      `
        opacity: 0;
    `};
  }
  &:nth-of-type(3) {
    top: 30px;

    ${({ isMenuOpen }): string | boolean =>
      isMenuOpen &&
      `
        transform: translateY(-15px) rotate(45deg);
        bottom: 0;
    `};
  }
`;

const BUTTON_MenuBg = styled.button<{ isMenuOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${LAYER.menuBg};
  transition: ${TRANSITION.menu};

  ${({ isMenuOpen }): string | boolean =>
    isMenuOpen &&
    `
      width: 100vw;
      height: 100vh;
      background-color: ${COLOR.menu.bg};
    `};
`;

export { Header };

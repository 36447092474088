import React from 'react';
import styled from '@emotion/styled';
import { COLOR, MEDIA_QUERY } from '../styles/variables';
import { containerStyle, blockStyle } from '../styles/common';

type Props = {
  title?: string;
  children: React.ReactNode;
};

const CommonBlock = (prop: Props): JSX.Element => {
  const { title, children } = prop;

  return (
    <SECTION_Wrap>
      {title && <H2_Title>{title}</H2_Title>}
      {children}
    </SECTION_Wrap>
  );
};

const SECTION_Wrap = styled.section`
  ${containerStyle}
  ${blockStyle}
`;

const H2_Title = styled.h2`
  font-size: 3rem;
  margin: -8px 0 20px;
  color: ${COLOR.text.blockTitle};

  @media (min-width: ${MEDIA_QUERY.middle}) {
    font-size: 5rem;
  }
`;

export { CommonBlock };

// ダークモード判定
// モードによって色を出し分ける
// const isLightMode = !window.matchMedia('(prefers-color-scheme: dark)').matches;

export const COLOR = {
  key: '#3893C8',
  text: {
    normal: '#333',
    sub: '#757575',
    blockTitle: 'rgba(0, 0, 0, 0.4)',
  },
  article: {
    hr: '#bbb',
    titleUnderLine: '#ddd',
    listDisc: '#ccc',
    linkText: '#888',
    inlineCodeBg: '#f0f0f0',
    inlineCodeBorder: '#ddd',
    codeTitleBg: '#757575',
    codeTitleColor: '#fff',
  },
  button: {
    border: '#ccc',
    hoverBg: '#eee',
  },
  contentBg: '#f5f7fb',
  blockBg: '#fff',
  gradation: {
    start: '#2980b9',
    middle: '#6dd5fa',
    end: '#c3efff',
  },
  footer: {
    text: '#fff',
    logo: '#fff',
  },
  hero: {
    wave: '#f5f7fb',
    title: '#fff',
    text: 'rgba(255, 255, 255, 0.8)',
  },
  pagenation: {
    wrapBg: '#3893C8',
    wrapText: '#fff',
  },
  menu: {
    bg: 'rgba(0, 0, 0, 0.5)',
    contentBg: '#fff',
    contentHover: '#f8f8f8',
    contentBorder: '#ddd',
  },
  sns: {
    twitter: '#1da1f2',
    facebook: '#1877f2',
  },
};

export const SIZE = {
  container: {
    percentage: '94%',
    max: '1120px',
  },
  block: {
    max: '900px',
  },
  ad: {
    max: '800px',
  },
};

export const MEDIA_QUERY = {
  max: '1120px',
  large: '900px',
  middle: '768px',
  darkMode: '@media (prefers-color-scheme: dark)',
};

export const SHADOW = {
  block: '0 2px 4px rgba(67, 133, 187, 0.1)',
  hero: {
    catch: '0 0 20px rgba(0, 0, 0, 0.5)',
  },
};

export const TRANSITION = {
  normal: '0.3s',
  menu: '0.5s',
};

export const OPACITY = {
  linkHover: '0.7',
};

export const LAYER = {
  menuBg: '100',
  menuContent: '1000',
  menuButton: '10000',
};

// mixin的なスタイル
import { css } from '@emotion/react';
import { COLOR, SHADOW, MEDIA_QUERY, SIZE, TRANSITION } from './variables';

export const containerStyle = css`
  width: ${SIZE.container.percentage};
  max-width: ${SIZE.container.max};
  margin: 0 auto;
`;

export const blockStyle = css`
  max-width: ${SIZE.block.max};
  background-color: ${COLOR.blockBg};
  border-radius: 8px;
  box-shadow: ${SHADOW.block};
  padding: 20px 16px;

  @media (min-width: ${MEDIA_QUERY.middle}) {
    padding: 32px;
  }
`;

/* 文字詰を元に戻す */
export const resetFontFeatureStyle = css`
  font-feature-settings: initial;
  letter-spacing: 0;
`;

export const buttonHoverStyle = css`
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${COLOR.button.hoverBg};
    transform: scaleX(0);
    transform-origin: 50%;
    transition-property: transform;
    transition-duration: ${TRANSITION.normal};
    transition-timing-function: ease-out;
  }

  &:hover {
    &::before {
      transform: scaleX(1);
    }
  }
`;

export const MAIL_ADDRESS = 'frontenddesignerblog@gmail.com';

export const TWITTER_ACCOUNT = '@fe__designer';

export const MODULE_TITLE = {
  RECENT_ARTICLE: 'Recent Article',
  ARTICLE_CATEGORY: 'Article Category',
  CATEGORY_ARTILCE: 'Article About', // 末尾にカテゴリ名をつけて出力される
  ABOUT_BLOG: 'About',
};

export const META_INFO = {
  ARTICLE_LIST: {
    TITLE: '記事一覧',
    DESCRIPTION:
      'Fe-Designerの記事一覧。HTML/CSS/JavaScriptなどの技術的な話、デザインのナレッジ、サイトKPI改善のTIPSなどを紹介する記事を掲載しています',
  },
  CATEGORY: {
    // 先頭にカテゴリ名をつけて出力される
    TITLE: 'カテゴリー',
    DESCRIPTION_DEFAULT: 'カテゴリーの記事です',
    DESCRIPTION_HTML:
      'HTMLカテゴリーの記事です。HTMLのセマンティックな使い方、アクセシビリティに配慮した実装方法などを紹介していきます',
    DESCRIPTION_CSS:
      'CSSカテゴリーの記事です。StyledComponents、Emotion、Postcssなどの実装ナレッジを紹介しています。コピペで使えるようなCSSもありますのでご参考にしてください',
    DESCRIPTION_JAVASCRIPT:
      'JavaScriptカテゴリーの記事です。JS（jQuery）実装時に困りやすいところ、知っておくと便利な小技などを紹介しています',
    DESCRIPTION_GATSBYJS:
      'Gatsby.jsカテゴリーの記事です。Gatsby.js実装時に自分が詰まったところや工夫したところを中心に技術的なナレッジを紹介しています',
    DESCRIPTION_IMPROVEMENT:
      'サイト改善カテゴリーの記事です。実務で明日から使えるようなサイト改善手法、アイデアを紹介しています。サイトのKPIや目標の達成のために役立てれば幸いです',
    DESCRIPTION_SITEPRODUCTION:
      'サイト制作カテゴリーの記事です。外注する際、外注される際などの注意点、趣味でサイト作成する際に意識したいことなどを紹介しています',
    DESCRIPTION_UX:
      'UXカテゴリーの記事です。話題のアプリやサイトのUX分析や、UX向上のコツなどを紹介しています',
    DESCRIPTION_PR:
      'PRカテゴリーの記事です。作ったプロダクトをどうやってプロモーションしていくかのコツなどを紹介しています',
  },
  ABOUT: {
    TITLE:
      'Fe-Designerについてです。プライバシーポリシーや免責事項についても記載しています',
  },
  ERROR: {
    TITLE: 'ページが見つかりません',
  },
};

export const COMMON_TEXT = {
  ABOUT:
    'フロントエンドエンジニアとしてのスキルも身に付けたデザイナーになることを目指すブログサイト',
};

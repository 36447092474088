import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const Seo = (props): JSX.Element => {
  const {
    pagetitle,
    pagedesc,
    pagepath,
    pageimg,
    blogimg,
    pageimgw,
    pageimgh,
    requireJsonLd = false,
    publishDate,
    updatedAt,
  } = props;

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          lang
          description
          siteUrl
          locale
        }
      }
    }
  `);

  const title = pagetitle
    ? `${pagetitle} | ${data.site.siteMetadata.title}`
    : data.site.siteMetadata.title;

  const description = pagedesc || data.site.siteMetadata.description;

  const url = pagepath
    ? `${data.site.siteMetadata.siteUrl}${pagepath}`
    : data.site.siteMetadata.siteUrl;

  const imgurl = pageimg
    ? `${data.site.siteMetadata.siteUrl}${pageimg}`
    : blogimg || `${data.site.siteMetadata.siteUrl}/thumb.jpg`;
  const imgw = pageimgw || 1280;
  const imgh = pageimgh || 640;

  return (
    <Helmet>
      <html lang={data.site.siteMetadata.lang} />
      <title>{title}</title>
      <meta name="description" content={description} />

      <link rel="canonical" href={url} />

      <meta property="og:site_name" content={data.site.siteMetadata.title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={data.site.siteMetadata.locale} />

      <meta property="og:image" content={imgurl} />
      <meta property="og:image:width" content={imgw} />
      <meta property="og:image:height" content={imgh} />
      <meta name="twitter:card" content="summary_large_image" />

      <script type="application/ld+json">
        {requireJsonLd &&
          `
        {
          "@context": "http://schema.org",
          "@type": "NewsArticle",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "${url}"
          },
          "headline": "${title}",
          "image": "${imgurl}",
          "datePublished": "${publishDate}",
          "dateModified": "${updatedAt}",
          "author": {
            "@type": "Person",
            "name": "FE-Designer"
          },
          "publisher": {
            "@type": "Person",
            "name": "FE-Designer",
            "logo": {
              "@type": "ImageObject",
              "url": "https://fe-designer.com/icons/icon-512x512.png"
            }
          },
          "description": "${description}"
        }
    `}
      </script>
    </Helmet>
  );
};

export { Seo };

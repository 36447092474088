import { css } from '@emotion/react';
import { COLOR, OPACITY, TRANSITION } from './variables';

export const baseStyle = css`
  html {
    font-size: 62.5%;
  }

  body {
    font-size: 1.6rem;
    line-height: 1.4;
    font-family: 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN',
      'Hiragino Sans', Meiryo, sans-serif;
    font-feature-settings: 'palt';
    letter-spacing: 0.09em;
    word-break: break-all;
    text-align: justify;
    color: ${COLOR.text.normal};
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: ${TRANSITION.normal};
  }

  a:hover {
    opacity: ${OPACITY.linkHover};
  }

  button {
    transition: ${TRANSITION.normal};
  }

  button:hover {
    opacity: ${OPACITY.linkHover};
  }

  li {
    list-style: none;
  }

  img {
    width: 100%;
    height: auto;
    vertical-align: bottom;
  }

  svg {
    vertical-align: bottom;
  }
`;

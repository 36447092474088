import React from 'react';
import Link from 'gatsby-link';
import styled from '@emotion/styled';
import { COLOR } from '../styles/variables';
import { buttonHoverStyle } from '../styles/common';

type Props = {
  data: {
    category: string;
    categorySlug: string;
  }[];
};

const CategoryList = (prop: Props): JSX.Element => {
  return (
    <UL_List>
      {prop.data.map((node) => (
        <LI_Item key={node.category}>
          <Link to={`/category/${node.categorySlug}/`}>#{node.category}</Link>
        </LI_Item>
      ))}
    </UL_List>
  );
};

const UL_List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: -12px 0 0 -12px;
`;

const LI_Item = styled.li`
  margin: 12px 0 0 12px;
  font-size: 1.4rem;

  a {
    position: relative;
    display: block;
    padding: 4px 12px;
    border: solid 1px ${COLOR.button.border};
    border-radius: 1000px;
    overflow: hidden;
    ${buttonHoverStyle};
  }
`;

export { CategoryList };

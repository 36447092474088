import React from 'react';
import 'ress';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core';
import { Global } from '@emotion/react';
import { baseStyle } from '../styles/base';
import { Header } from './Header';
import { Footer } from './Footer';

config.autoAddCss = false;

export default ({ children }): JSX.Element => (
  <div>
    {/* ベースになるスタイルをグローバルに付与 */}
    <Global styles={baseStyle} />

    {/* ヘッダーフッターの間にコンテンツを挟む */}
    <Header />
    {children}
    <Footer />
  </div>
);

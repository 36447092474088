import React from 'react';
import styled from '@emotion/styled';
import { COLOR, MEDIA_QUERY } from '../styles/variables';

type Props = {
  children: React.ReactNode;
};

const CommonContent = (prop: Props): JSX.Element => {
  const { children } = prop;

  return <MAIN_Wrap>{children}</MAIN_Wrap>;
};

const MAIN_Wrap = styled.main`
  min-height: calc(100vh - 350px);
  padding: 20px 0;
  background-color: ${COLOR.contentBg};

  @media (min-width: ${MEDIA_QUERY.middle}) {
    padding: 40px 0;
  }
`;

export { CommonContent };
